import back1 from 
.start {
  padding-right: 10%;
  padding-left: 10%;
}
.background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.App {
  font-family: 'Geneva', sans-serif;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

h1{
  font-size: 70px;
}
.highlight {
  color: rgb(50, 61, 102); 
  font-weight: bold;
}

.navbar{
  border-top: 1px solid #343a40;
  border-radius: 0%;
  font-size: 30px;
}

.box{
  width: 100%;
  background-color: #343a40;
  padding: 50px;
  margin: 20px;
  border-radius: 5px;
  color: white;
}

.image-wrapper {
  display: inline-block;
  margin: 0 10px;
}

.image {
  margin-top: 50px;
  max-height: 150px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.image:hover {
  transform: scale(1.1);
}


.form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: #333;
}

.form input[type="text"],
.form input[type="email"],
.form textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  box-sizing: border-box;
}

.form input[type="text"]:focus,
.form input[type="email"]:focus,
.form textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.form input[type="submit"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form input[type="submit"]:hover {
  background-color: #0056b3;
}

.form input[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form br {
  display: none;
}

.form textarea {
  resize: vertical;
}

.info-box {
  width: 100%;
  border: 1px solid #ddd;
  margin-top: 100px;
  padding: 60px;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

